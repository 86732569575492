import React from "react";

const Svg = () => {
  return (
    <svg version="1.1" id="Layer_1" width="23" height="16" x="0px" y="0px" viewBox="0 0 309.3 184.5">
      <path
        d="M308.9,95.3c0-0.1,0.1-0.3,0.1-0.4c0.1-0.2,0.1-0.4,0.1-0.6c0-0.1,0-0.2,0.1-0.3c0.1-0.3,0.1-0.6,0.1-0.9l0,0
                                       c0-0.3,0-0.6,0-0.9c0-0.1,0-0.2,0-0.2c0-0.2,0-0.5,0-0.7c0-0.1,0-0.2,0-0.3c0-0.2-0.1-0.5-0.1-0.7s-0.1-0.3-0.1-0.5
                                       s-0.1-0.4-0.1-0.5c-0.1-0.2-0.1-0.4-0.2-0.5c0-0.1-0.1-0.2-0.1-0.2c-0.5-1.4-1.3-2.7-2.4-3.8c-0.1-0.1-0.2-0.2-0.3-0.3L224.6,3.1
                                       c-4.1-4.1-10.8-4.1-14.9,0s-4.1,10.8,0,14.9l63.7,63.7h-263C4.7,81.7,0,86.4,0,92.2c0,5.8,4.7,10.5,10.5,10.5h263l-63.8,63.8
                                       c-4.1,4.1-4.1,10.8,0,14.9c2,2.1,4.7,3.1,7.4,3.1l0,0c2.7,0,5.4-1,7.5-3.1l81.8-81.8l0.1-0.1c0.1-0.1,0.1-0.1,0.1-0.2
                                       c0.3-0.3,0.5-0.6,0.7-0.8c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.2,0.2-0.3,0.3-0.5
                                       c0.1-0.2,0.2-0.5,0.3-0.7l0,0c0.1-0.1,0.1-0.2,0.1-0.4C308.8,95.7,308.9,95.5,308.9,95.3z"
      />
    </svg>
  );
};

export default Svg;
