
export async function login(email, password, token) {

    let data = await fetch(`${process.env.REACT_APP_WEBSITE_API_URL}/seller/seller-login`, {
        method: "POST",
        body: JSON.stringify({
            email,
            password,
            token
        }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        },
    }).then(response => {
        return response.json();
    }).then(data => {
        return data;
    }).catch(err => {
        console.log(err)
    })

    const authPromise = new Promise((resolve, reject) => {
        if (typeof data.token == 'undefined') {
            reject('Invalid credentials');
        }
        resolve({
            token: data.token,
        });
    });

   
    return authPromise;
}