import React, { useContext, useEffect, useState } from "react";
import "./LoginUser.css";
import { toast, ToastContainer, Slide } from "react-toastify";
import { AuthContext } from "./store/authContext";
import "../Common/css/loader.css";

const Coupon = () => {
  const [voucherFrmData, setVoucherFrmData] = useState({
    voucher_code: "",
    membership_code: "",
  });
  const authCtx = useContext(AuthContext);
  const [spinner, setSpinner] = useState(true);

  useEffect(() => {
    setTimeout(() => setSpinner(false), 1000);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const fetchData = async () => {
      let headers = new Headers(),
        formdata = new FormData(),
        url = `${process.env.REACT_APP_WEBSITE_API_URL}/seller/coupon-validate`;

      headers.append("Authorization", `Bearer ${authCtx.token.token}`);
      formdata.append("voucher_code", `${voucherFrmData.voucher_code}`);
      formdata.append("membership_code", `${voucherFrmData.membership_code}`);

      let requestOptions = {
        method: "POST",
        headers,
        body: formdata,
        redirect: "follow",
      };

      await fetch(url, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data?.status === true) {
            toast.success(data?.message, {
              position: toast.POSITION.TOP_CENTER,
              closeButton: false,
            })
          } else if (data?.status === false) {
            toast.error(data?.message, {
              position: toast.POSITION.TOP_CENTER,
              closeButton: false,
            })
          } else if (!data?.voucher_code && data?.membership_code){
            toast.error(data?.membership_code[0], {
              position: toast.POSITION.TOP_CENTER,
              closeButton: false,
            })
          } else if (!data?.membership_code && data?.voucher_code){
            toast.error(data?.voucher_code[0], {
              position: toast.POSITION.TOP_CENTER,
              closeButton: false,
            })
          } else if (data?.membership_code && data?.voucher_code){
            toast.error(data?.membership_code[0] && data?.voucher_code[0], {
              position: toast.POSITION.TOP_CENTER,
              closeButton: false,
            })
          }
        })
    };
    fetchData();
  }
  return spinner ? (
    <div className="loader">
      <div className="outer"></div>
      <div className="middle"></div>
      <div className="inner"></div>
    </div>
  ) : (
    <div className="login__wrap">
      <div className="login__wrapp__inner"></div>
      <div className="login__holder">
        <div className="form__holder">
          <div className="form__holder__inner">
            <h2>
              Validate <span>Voucher</span>
            </h2>
            <div className="form__wrapp">
              <form>
                <div className="form-group form__group">
                  <input
                    className="form-control form__control"
                    id="Coupon"
                    autoComplete="off"
                    type="text"
                    value={voucherFrmData.voucher_code}
                    onChange={(e) => {
                      setVoucherFrmData({
                        ...voucherFrmData,
                        ["voucher_code"]: e.target.value,
                      });
                    }}
                    required
                  />
                  <label htmlFor="Coupon">Voucher Code</label>
                </div>
                <div className="form-group form__group">
                  <input
                    className="form-control form__control"
                    id="Invoice"
                    autoComplete="off"
                    type="text"
                    value={voucherFrmData.membership_code}
                    onChange={(e) => {
                      setVoucherFrmData({
                        ...voucherFrmData,
                        ["membership_code"]: e.target.value,
                      });
                    }}
                    required
                  />
                  <label htmlFor="Invoice">User Membership Code</label>
                </div>

                {/* {couponFrmData.coupon_id && (
                    <>
                      <div className="form-group form__group form__group__select">
                        <label htmlFor="Product_Model">Select Model</label>
                        <select className="form-control form__control form__control__selectpiker" onChange={(e) => { setCouponFrmData({ ...couponFrmData, ['product_id']: e.target.value }) }}>
                          {productInfo.length && productInfo.map(product => <option value={product.id} key={product.id}>{`${product.name} - ${product.model_no}`}</option>)}
                        </select>
                        <label htmlFor="Product_Model">Product Model</label>
                      </div>
                      <div className="form-group form__group">
                        <input className="form-control form__control" id="Invoice" autoComplete="off" type="text" value={couponFrmData.invoice_no} onChange={(e) => { setCouponFrmData({ ...couponFrmData, ['invoice_no']: e.target.value }) }} required />
                        <label htmlFor="Invoice">Invoice No.</label>
                      </div>
                    </>
                  )} */}

                <button
                  className="submit__btn"
                  type="button"
                  onClick={handleSubmit}
                >
                  Validate
                </button>
              </form>
              <ToastContainer transition={Slide} />
            </div>
          </div>
        </div>
      </div>
    </div>

    // <div className="login__wrap">
    //   <div className="logo__holder">
    //     <img src={logo} alt="" />
    //   </div>
    //   <div className="login__inner__wrapp">
    //     <div className="login__holder">
    //       <div className="form__holder">
    //         <h3>Validate <span>Coupon</span></h3>
    //         <div className="form__wrapp">
    //           <form>
    //             <div className="form-group form__group">
    //               <input type="text" placeholder="Coupon code" value={couponCode} onChange={(e) => { setCouponCode(e.target.value) }} required className="form-control form__control" />
    //             </div>
    //             <button className="submit__btn" type="button" onClick={handleSubmit}>Submit</button>
    //           </form>
    //           <ToastContainer transition={Slide} />
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default Coupon;
